<template>
    <Layout header-class="header-transparent" :show-newsletter="true" :show-cta="false">
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-1 height-800 bg_image"
            style="background-image: url('https://qiniu.tongzeit.com/app_website/ic_partner_bg.png')">
            <div class="row" style="position: relative; left:19%;bottom: 10%;">
                <div class="col-lg-12">
                    <div class="inner">
                        <h1 class="title display-one" style="color: #2B3059;" data-aos="fade-up" data-aos-delay="150">
                            通泽伙伴
                        </h1>

                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="inner">
                        <div class="display-one" style="font-size: 40px;color: #2B3059;" data-aos="fade-up"
                            data-aos-delay="150">
                            产康知识一手掌握
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Slider Area -->
        <div class="rwt-contact-area rn-section-gap">
            <div class="container">
                <SectionTitle text-align="center" subtitle="Device Binding" title="设备绑定" />
                <div class="row row--15 service-wrapper">
                    <div class="col-lg-6 col-md-6 col-12 mt--30" v-for="(service, index) in serviceList"
                        :key="index">
                        <div class="service service__style--2 text-center" data-aos="fade-up" data-aos-delay="70">
                            <div class="inner">
                                <div class="image">
                                    <img :src="service.image" alt="Service Images" />
                                </div>
                                <div class="content">
                                    <h4 class="title">
                                        <router-link to="/service-details" v-html="service.title" />
                                    </h4>
                                    <p class="description" v-html="service.description" />
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 mb--40 text-center">
                <SectionTitle text-align="center" subtitle="Product View" title="产品查看" />
            </div>
        </div>
        <div class="slider-area slider-style-1 height-950 bg_image"  data-aos="fade-up" data-aos-delay="150"
            style="background-image: url('https://qiniu.tongzeit.com/app_website/ic_partner_product_bg.png')">
        </div>
        <div class="rwt-contact-area rn-section-gap">
            <div class="container">
                <SectionTitle text-align="center" subtitle="Device Renewal" title="设备续费" />
                <div class="row row--15 service-wrapper">
                    <div class="col-lg-6 col-md-6 col-12 mt--30" v-for="(service, index) in renewalList"
                        :key="index">
                        <div class="service service__style--2 text-center" data-aos="fade-up" data-aos-delay="70">
                            <div class="inner">
                                <div class="image">
                                    <img :src="service.image" alt="Service Images" />
                                </div>
                                <div class="content">
                                    <h4 class="title">
                                        <router-link to="/service-details" v-html="service.title" />
                                    </h4>
                                    <p class="description" v-html="service.description" />
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center mb--100">
            <h4 class="title">通泽伙伴APP下载</h4>
            <div class="image">
               <img src="@/assets/images/tongze/ic_partner_download.png" style="width: 150px;height:150px" alt="Service Images" />
             </div>
             <span class="subtitle b2 text-capitalize color-black">扫描下载APP</span>
        </div>
    </Layout>
</template>

<script>
import Layout from '../../components/common/Layout'
import Separator from '../../components/elements/separator/Separator'
import SectionTitle from '../../components/elements/sectionTitle/SectionTitle'
import AboutFour from '../../components/elements/about/AboutFour'
import ServiceOne from '../../components/elements/service/ServiceOne'
import Counter from '../../components/elements/counterUp/Counter'

export default {
    name: 'About',
    components: { Counter, ServiceOne, AboutFour, SectionTitle, Separator, Layout },
    data() {
        return {
            serviceList: [
                {
                    image: require('@/assets/images/tongze/ic_partner_bind.png'),
                    title: '添加设备',
                    description: '绑定设备，关注设备到期时间'
                },
                {
                    image: require('@/assets/images/tongze/ic_partner_scan.png'),
                    title: '扫码绑定',
                    description: 'APP扫码快速绑定'
                }
            ],
            renewalList: [
                {
                    image: require('@/assets/images/tongze/ic_partner_renewal1.png'),
                    title: '扫码续费',
                    description: '用户扫描二维码后，选择续费套餐'
                },
                {
                    image: require('@/assets/images/tongze/ic_partner_renewal2.png'),
                    title: '密钥获取',
                    description: '续费成功后，将激活密钥输入设备密码处，完成激活'
                }
            ]
        }
    },
    methods: {
        switchImg() {

        }
    },
}
</script>