<template>
    <ul class="mainmenu">
        <li class="has-droupdown has-menu-child-item text-center" style="width: 100px;">
            <router-link to="/#">首页
            </router-link>
        </li>
        <li class="has-droupdown has-menu-child-item text-center" style="width: 160px;">
            <a href="#">
                APP
                <Icon name="plus" class="collapse-icon plus"/>
                <Icon name="minus" class="collapse-icon minus"/>
            </a>
            <ul class="submenu" style="width: 160px;min-width: 0px;padding: 0px;">
                <li><router-link class="text-center itemli" to="/tongze-homeland">通泽家园</router-link></li>
                <li><router-link class="text-center itemli" to="/tongze-partner">通泽伙伴</router-link></li>
                <li><router-link class="text-center itemli" to="/enze-mother">恩泽妈妈</router-link></li>
            </ul>
        </li>
        <li class="text-center" style="width: 100px;"><router-link to="/tongze-product" >产品中心</router-link></li>
        <li class="text-center" style="width: 100px;"><router-link to="/tongze-information">通泽资讯</router-link></li>
        <li class="text-center" style="width: 100px;"><router-link to="/tongze-about">关于通泽</router-link></li>
    </ul>
</template>

<script>
    import Icon from "../../icon/Icon";
    export default {
        name: 'Nav',
        components: {Icon}
    }
</script>
<style lang="scss">
    .itemli{
        line-height: 44px;
        height: 50px!important;
        margin: 0!important;
    }
    .itema{
        padding: 0;
    }
</style>