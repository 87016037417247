<template>
    <Layout header-class="header-transparent" :show-newsletter="true" :show-cta="false">
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-1 height-850 bg_image"
            style="background-image: url('https://qiniu.tongzeit.com/app_website/ic_index_item1.png')">
        </div>
        <!-- End Slider Area -->
        <div class="rwt-contact-area rn-section-gap">
            <div class="row">
                <div class="col-lg-12 mb--40 text-center">
                    <SectionTitle text-align="center" subtitle="Health Producing Instrument" title="产康仪器" />
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row row-cols-lg-2 row-cols-md-1 ">
                <div v-for="item in dataArray" :key="item.id" class="col product-block">
                    <div class="product-item">
                        <div class="product-item-img">
                            <img :src="item.productIcon" style="display: block;width: 100%; height: 100%" />
                        </div>
                        <div class="product-item-info">
                            <div class="product-item-info-title">{{ item.productName }}</div>
                            <div class="product-item-info-content">{{ item.productShortIntro }}</div>
                            <div class="product-item-info-button-block">
                                <router-link :to="{path:'/tongze-productdetail',query: {productId: item.productId} }"
                                    class="product-item-info-button-sub">查看详情
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container">
            <nav aria-label="Page navigation example">
                <ul class="pagination pagination-lg justify-content-end">
                    <li v-if="currentPage!=1" class="page-item">
                        <a class="page-link" @click="getMyData(currentPage-11)">上一页</a>
                    </li>
                    <li v-else class="page-item disabled">
                        <a class="page-link">上一页</a>
                    </li>
                    <li :class="item == currentPage ? 'page-item active' : 'page-item'" v-for="item in pages"
                        :key="item.id">
                        <a class="page-link" @click="getMyData(item)">{{ item }}</a>
                    </li>
                    <li v-if="currentPage!=pages" class="page-item">
                        <a class="page-link" @click="getMyData(currentPage+1)">下一页</a>
                    </li>
                    <li v-else class="page-item disabled">
                        <a class="page-link">下一页</a>
                    </li>
                </ul>
            </nav>
        </div>

    </Layout>
</template>

<script>
import Layout from '../../components/common/Layout'
import Separator from '../../components/elements/separator/Separator'
import SectionTitle from '../../components/elements/sectionTitle/SectionTitle'
import AboutFour from '../../components/elements/about/AboutFour'
import ServiceOne from '../../components/elements/service/ServiceOne'
import Counter from '../../components/elements/counterUp/Counter'

import axios from 'axios'

export default {
    name: 'About',
    components: { Counter, ServiceOne, AboutFour, SectionTitle, Separator, Layout },
    data() {
        return {
            pages: 1,
            currentPage: 1,
            dataArray: [],
        }
    },
    methods: {
        getMyData(pageNum = 1) {
            let that = this

            const instance = axios.create({
                baseURL: process.env.VUE_APP_URL,
                timeout: 3000,
                // headers: { 'X-Custom-Header': 'foobar' }
            });

            instance.post('official_website/getProductList', {
            }, {
                params: {
                    pageNum: pageNum,
                    limitSize: 10
                }
            }).then(function (response) {
                let sumTotal = response.data.data.recordCounts

                that.dataArray = response.data.data.dataList
                that.pages = Math.ceil(sumTotal / 10)
                that.currentPage = pageNum
            }).catch(function (error) {
                console.log(error);
            });
        }
    },
    mounted: function () {
        this.getMyData()
    }
}
</script>

<style lang="scss">
.product-block {
    height: 368px;
}

.product-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 348px;
    background: #FFFFFF;
    box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.07);
}

.product-item:hover {
    height: 348px;
    background: #FFFFFF;
    box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.1);
    border: 1px solid #42A2E4;
}

.product-item-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 213px;
    height: 316px;
    padding: 16px;
}

.product-item-img>img {
    object-fit: scale-down;
}

.product-item-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.product-item-info-title {
    height: 66px;
    margin-left: 20px;
    font-size: 24px;
    font-weight: 500;
    color: #2B3059;
    line-height: 33px;
}

.product-item-info-content {
    margin-left: 20px;
    width: 264px;
    height: 120px;
    font-size: 16px;
    font-weight: 400;
    color: #2B3059;
    line-height: 30px;
    margin-top: 10px;
    display: flex;
    justify-content: left;
    align-items: center;
}

.product-item-info-button-block {
    display: flex;
    margin-right: 20px;
    justify-content: flex-end;
}

.product-item-info-button-sub {
    width: 56px;
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 28px;
    width: 120px;
    height: 28px;
    background: #2B3059;
    text-align: center;
}
</style>
