import Vue from 'vue'
import VueRouter from 'vue-router'
import TongZeIndex from '../views/tongze/index'
import TongZeAbout from '../views/tongze/about'
import TongZeHomeLand from '../views/tongze/homeland'
import TongZePartner from '../views/tongze/partner'
import EnZeMother from '../views/tongze/enzemother'
import InforMation from '../views/tongze/information'
import Product from '../views/tongze/product'
import ProductList from '../views/tongze/productlist'
import ProductDetail from '../views/tongze/productdetail'
import InfoDetail from '../views/tongze/informationdetail'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'TongZeIndex',
        component: TongZeIndex,
        meta: {
            title: '首页',
        },
    },
    {
        path: '/tongze-about',
        name: 'TongZeAbout',
        component: TongZeAbout,
        meta: {
            title: '关于通泽',
        },
    },
    {
        path: '/tongze-homeland',
        name: 'TongZeHomeLand',
        component: TongZeHomeLand,
        meta: {
            title: 'APP-通泽家园',
        },
    },
    {
        path: '/tongze-partner',
        name: 'TongZePartner',
        component: TongZePartner,
        meta: {
            title: 'APP-通泽伙伴',
        },
    },
    {
        path: '/enze-mother',
        name: 'EnZeMother',
        component: EnZeMother,
        meta: {
            title: 'APP-恩泽妈妈',
        },
    },
    {
        path: '/tongze-information',
        name: 'InforMation',
        component: InforMation,
        meta: {
            title: '通泽资讯',
        },
    },
    // {
    //     path: '/tongze-index',
    //     name: 'TongZeIndex',
    //     component: TongZeIndex,
    //     meta: {
    //         title: '首页',
    //     },
    // },
    {
        path: '/tongze-product',
        name: 'Product',
        component: Product,
        meta: {
            title: '产品中心',
        },
    },
    {
        path: '/tongze-productlist',
        name: 'ProductList',
        component: ProductList,
        meta: {
            title: '产康仪器',
        },
    },  {
        path: '/tongze-productdetail',
        name: 'ProductDetail',
        component: ProductDetail,
        meta: {
            title: '产品详情',
        },
    }, {
        path: '/tongze-informationdetail',
        name: 'InfoDetail',
        component: InfoDetail,
        meta: {
            title: '资讯详情',
        },
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
    window.scrollTo(0, 0)
});

export default router
