<template>
    <Layout header-class="header-transparent" :show-newsletter="true" :show-cta="false">
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-1 height-950 bg_image"
          style="background-image: url('https://qiniu.tongzeit.com/app_website/ic_homeland_bg.png')">
            <div class="row" style="position: relative; left:50%;bottom: 10%;">
                <div class="col-lg-12">
                    <div class="inner">
                        <h1 class="title display-one" data-aos="fade-up" data-aos-delay="150">
                            通泽家园
                        </h1>

                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="inner">
                        <div class="display-one" style="font-size: 40px;color: #FFFFFF;" data-aos="fade-up"
                            data-aos-delay="150">
                            一站式医疗级产康开启者
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Slider Area -->
        <div class="rwt-contact-area rn-section-gap">
            <div class="container">
                <div class="row row--15 service-wrapper">
                    <div class="col-lg-4 col-md-6 col-sm-12 col-12" v-for="(service, index) in serviceList"
                        :key="index">
                        <div class="service service__style--2 text-center" data-aos="fade-up" data-aos-delay="70">
                            <div class="inner">
                                <div class="content">
                                    <h4 class="title">
                                        <router-link to="/service-details" v-html="service.title" />
                                    </h4>
                                    <p class="description" v-html="service.description" />
                                </div>
                                <div class="image">
                                    <img :src="service.image" alt="Service Images" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 mb--40 text-center">
                <SectionTitle text-align="center" subtitle="Equipment Function" title="设备功能" />
                <span class="subtitle b2 text-capitalize color-black">通泽医疗系列产后恢复产品，为您提供全周期产康技术支持，悉心呵护每一位产后妈妈。</span>
            </div>
        </div>
        <div class="slider-area slider-style-1 height-950 bg_image"  data-aos="fade-up" data-aos-delay="150"
          style="background-image: url('https://qiniu.tongzeit.com/app_website/ic_homeland_function.png')">
        </div>
        <div class="slider-area slider-style-1 height-950 bg_image" data-aos="fade-up" data-aos-delay="150" 
          style="background-image: url('https://qiniu.tongzeit.com/app_website/ic_homeland_function2.png')">
        </div>
        <div class="text-center mb--100">
            <h4 class="title">通泽家园APP下载</h4>
            <div class="image" >
               <img src="@/assets/images/tongze/ic_homeland_download.png" style="width: 150px;height:150px" alt="Service Images" />
             </div>
             <span class="subtitle b2 text-capitalize color-black">扫描下载APP</span>
        </div>
    </Layout>
</template>

<script>
import Layout from '../../components/common/Layout'
import Separator from '../../components/elements/separator/Separator'
import SectionTitle from '../../components/elements/sectionTitle/SectionTitle'
import AboutFour from '../../components/elements/about/AboutFour'
import ServiceOne from '../../components/elements/service/ServiceOne'
import Counter from '../../components/elements/counterUp/Counter'

export default {
    name: 'About',
    components: { Counter, ServiceOne, AboutFour, SectionTitle, Separator, Layout },
    data() {
        return {
            serviceList: [
                {
                    image: require('@/assets/images/tongze/ic_homeland_data.png'),
                    title: '全方位数据分析',
                    description: '客户数据一目了然，设备信息实时更新'
                },
                {
                    image: require('@/assets/images/tongze/ic_homeland_customer.png'),
                    title: '客户管理',
                    description: '机构绑定，负责人随时联系'
                },
                {
                    image: require('@/assets/images/tongze/ic_homeland_device.png'),
                    title: '设备管理',
                    description: '设备到期时间显示，及时跟踪设备状况'
                }
            ]
        }
    },
    methods: {
        switchImg() {

        }
    },
}
</script>