<template>
    <Layout header-class="header-transparent" :show-newsletter="true" :show-cta="false">
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-1 height-1000 bg_image"
            style="background-image: url('https://qiniu.tongzeit.com/app_website/ic_mother_bg.png')">
            <div class="row" style="position: relative; left:19%;bottom: 18%;">
                <div class="col-lg-12">
                    <div class="inner">
                        <h1 class="title display-one" data-aos="fade-up" data-aos-delay="150">
                            恩泽妈妈
                        </h1>

                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="inner">
                        <div class="display-one" style="font-size: 40px;color: #ffffff;" data-aos="fade-up"
                            data-aos-delay="150">
                            专注盆底及产后康复方案提供
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Slider Area -->
        <div class="rwt-contact-area rn-section-gap">
            <div class="container">
                <div class="row row--15 service-wrapper">
                    <div class="col-lg-4 col-md-6 col-sm-12 col-12" v-for="(service, index) in serviceList"
                        :key="index">
                        <div class="service gallery-style text-center" data-aos="fade-up" data-aos-delay="70">
                            <div class="inner">
                                <div class="image">
                                    <img :src="service.image" alt="Service Images" />
                                </div>
                                <div class="content">
                                    <h4 class="title">
                                        <router-link to="/service-details" v-html="service.title" />
                                    </h4>
                                    <p class="description" v-html="service.description" />
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 mb--40 text-center">
                <SectionTitle text-align="center" subtitle="Historical Evaluation Report" title="历史评估报告" />
            </div>
        </div>
        <div class="slider-area slider-style-1 height-950 bg_image"  data-aos="fade-up" data-aos-delay="150"
            style="background-image: url('https://qiniu.tongzeit.com/app_website/ic_mother_test.png')">
        </div>
        <div class="row">
            <div class="col-lg-12 mb--40 text-center">
                <SectionTitle text-align="center" subtitle="Training Course Progress" title="训练课程进度" />
            </div>
        </div>
        <div class="slider-area slider-style-1 height-950 bg_image"  data-aos="fade-up" data-aos-delay="150"
             style="background-image: url('https://qiniu.tongzeit.com/app_website/ic_mother_progress.png')">
        </div>
        <div class="text-center mb--100">
            <h4 class="title">恩泽妈妈小程序</h4>
            <div class="image">
               <img src="@/assets/images/tongze/ic_enzemother_download.png" style="width: 150px;height:150px" alt="Service Images" />
             </div>
             <span class="subtitle b2 text-capitalize color-black">扫描打开小程序</span>
        </div>
    </Layout>
</template>

<script>
import Layout from '../../components/common/Layout'
import Separator from '../../components/elements/separator/Separator'
import SectionTitle from '../../components/elements/sectionTitle/SectionTitle'
import AboutFour from '../../components/elements/about/AboutFour'
import ServiceOne from '../../components/elements/service/ServiceOne'
import Counter from '../../components/elements/counterUp/Counter'

export default {
    name: 'About',
    components: { Counter, ServiceOne, AboutFour, SectionTitle, Separator, Layout },
    data() {
        return {
            serviceList: [
                {
                    image: require('@/assets/images/tongze/ic_mother_item1.png'),
                    title: '扫码同步档案',
                    description: '扫描设备二维码，即可实现档案同步'
                },
                {
                    image: require('@/assets/images/tongze/ic_mother_item2.png'),
                    title: '历史评估报告',
                    description: '历史评估报告显示，查询报告详情'
                },
                {
                    image: require('@/assets/images/tongze/ic_mother_item3.png'),
                    title: '训练课程进度',
                    description: '设备到期时间显示，及时跟踪设备状况'
                }
            ]
        }
    },
    methods: {
        switchImg() {

        }
    },
}
</script>