<template>
    <div>
        <footer v-if="data.showFooter" class="rn-footer footer-style-default footer-style-1">
            <div class="footer-top">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div class="rn-footer-widget">
                                <Logo/>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-6 col-12">
                            <div class="rn-footer-widget">
                                <div class="widget-menu-top">
                                    <h4 class="title">关于通泽</h4>
                                    <div class="inner">
                                        <ul class="footer-link link-hover">
                                            <li><router-link to="/tongze-about">企业简介</router-link></li>
                                            <li><router-link to="/tongze-about">企业理念</router-link></li>
                                            <li><router-link to="/tongze-about">联系我们</router-link></li>
                                        </ul>
                                    </div>
                                </div>
                                <!-- <div class="widget-menu-bottom">
                                    <h4 class="title">Products</h4>
                                    <div class="inner">
                                        <ul class="footer-link link-hover">
                                            <li><router-link to="/about">About</router-link></li>
                                            <li><router-link to="/portfolio">Portfolio</router-link></li>
                                            <li><router-link to="/contact">Contact</router-link></li>
                                            <li><router-link to="/service">Service</router-link></li>
                                        </ul>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-6 col-12">
                            <div class="rn-footer-widget">
                                <div class="widget-menu-top">
                                    <h4 class="title">产品中心</h4>
                                    <div class="inner">
                                        <ul class="footer-link link-hover">
                                            <li><router-link to="/tongze-product">产康产品</router-link></li>
                                            <li><router-link to="/tongze-productlist">产康仪器</router-link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-6 col-12">
                            <div class="rn-footer-widget">
                                <div class="widget-menu-top">
                                    <h4 class="title">APP</h4>
                                    <div class="inner">
                                        <ul class="footer-link link-hover">
                                            <li><router-link to="/tongze-homeland">通泽家园</router-link></li>
                                            <li><router-link to="/tongze-partner">通泽伙伴</router-link></li>
                                            <li><router-link to="/enze-mother">恩泽妈妈</router-link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-6 col-12">
                            <div class="rn-footer-widget">
                                <div class="widget-menu-top">
                                    <h4 class="title">媒体中心</h4>
                                    <div class="inner">
                                        <ul class="footer-link link-hover">
                                            <li><router-link to="/tongze-information">科普知识</router-link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <Copyright v-if="data.showCopyright"/>
        <ScrollTop/>
    </div>
</template>

<script>
    import Copyright from './Copyright'
    import ScrollTop from './ScrollTop'
    import Logo from '../../elements/logo/Logo'

    export default {
        name: 'FooterThree',
        components: {Logo, ScrollTop, Copyright},
        props: {
            data: {
                default: null
            }
        }
    }
</script>