<template>
    <Layout header-class="header-transparent" :show-newsletter="true" :show-cta="false">
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-1 height-850 bg_image" 
            style="background-image: url('https://qiniu.tongzeit.com/app_website/ic_about_bg.png')">
        </div>
        <!-- End Slider Area -->
        <div class="rwt-contact-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 mb--40">
                        <SectionTitle text-align="center" subtitle="About Tomzon" title="企业简介" />
                    </div>
                    <span
                        class="subtitle b2 text-capitalize">&#12288;&#12288;广州通泽医疗科技有限公司是一家专注产后康复领域的高新技术企业。公司由经验丰富医生团队、天䔳药物专家、医疗器械专家、市场营销专家共同成立。作为一站式医疗级产康开启者，通泽医疗致力于推动将高新医疗科技与客户需求完羙结合，让产后康复更简单，让天下妈妈更健康！
                        通泽医疗贯彻创新驱动发展的战略，研发团队80%具备硕士以上学历，与国内顶级大学进行产、学、研合作，获得包括发明专利、实用新型专利、软件著作权等40多项知识产权，开发了一系列完善的产后康复仪器和天䔳植物产品，并且引进美国物理康复技术协会的EMCE康复技术解决方案，从専业的仪器、手法、产品和运动四个维度，实现医疗级康复效果。</span>
                </div>
            </div>
        </div>
        <div class="rwt-contact-area rn-section-gap">
            <div class="row">
                <div class="col-lg-12 mb--40">
                    <SectionTitle text-align="center" subtitle="Values" title="企业理念" />
                </div>
            </div>
            <div class="row mt_dec--30">
                <div class="col-lg-3 col-md-6 col-sm-6 col-12 mt--30  pr--0 pl--0">
                    <div class="rn-gallery icon-center" data-aos="fade-up">
                        <div class="thumbnail" @mouseenter="imgEnter(1)" @mouseleave="imgLeave(1)">
                            <img :src="dfaboutImg1"/>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-12 mt--30  pr--0 pl--0">
                    <div class="rn-gallery icon-center" data-aos="fade-up">
                        <div class="thumbnail" @mouseenter="imgEnter(2)" @mouseleave="imgLeave(2)">
                            <img  :src="dfaboutImg2" />
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-12 mt--30  pr--0 pl--0">
                    <div class="rn-gallery icon-center" data-aos="fade-up">
                        <div class="thumbnail" @mouseenter="imgEnter(3)" @mouseleave="imgLeave(3)">
                            <img  :src="dfaboutImg3" />
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-12 mt--30  pr--0 pl--0">
                    <div class="rn-gallery icon-center" data-aos="fade-up">
                        <div class="thumbnail" @mouseenter="imgEnter(4)" @mouseleave="imgLeave(4)">
                            <img  :src="dfaboutImg4" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="slider-area slider-style-1 height-850 bg_image"
            style="background-image: url('https://qiniu.tongzeit.com/app_website/img_about_item_bg.png')">
            <div class="container">
                <div class="row">
                    <H2 class="font-500 color-white">期待与您交流</H2>
                    <span class="subtitle b2 text-capitalize color-white">对产品有任何疑问或者希望我们为您推荐产品，我们随时提供帮助。<br>
                        敬请与我们取得联系，我们会尽快回复。</span>
                </div>
                <ServiceOne
                    service-style="service__style--1 bg-color-blackest radius mt--25"
                    icon-size="62"
                    text-align="center"
                />
            </div>

        </div>
    </Layout>
</template>

<script>
import Layout from '../../components/common/Layout'
import Separator from '../../components/elements/separator/Separator'
import SectionTitle from '../../components/elements/sectionTitle/SectionTitle'
import AboutFour from '../../components/elements/about/AboutFour'
import ServiceOne from '../../components/elements/service/ServiceOne'
import Counter from '../../components/elements/counterUp/Counter'

export default {
    name: 'About',
    components: { Counter, ServiceOne, AboutFour, SectionTitle, Separator, Layout },
    data() {
        return {
            aboutimg1: require('@/assets/images/tongze/img_about1.png'),
            aboutimg1Switch: require('@/assets/images/tongze/img_about1_switch.png'),
            dfaboutImg1:require('@/assets/images/tongze/img_about1.png'),

            aboutimg2: require('@/assets/images/tongze/img_about2.png'),
            aboutimg2Switch: require('@/assets/images/tongze/img_about2_switch.png'),
            dfaboutImg2:require('@/assets/images/tongze/img_about2.png'),

            aboutimg3: require('@/assets/images/tongze/img_about3.png'),
            aboutimg3Switch: require('@/assets/images/tongze/img_about3_switch.png'),
            dfaboutImg3:require('@/assets/images/tongze/img_about3.png'),


            aboutimg4: require('@/assets/images/tongze/img_about4.png'),
            aboutimg4Switch: require('@/assets/images/tongze/img_about4_switch.png'),
            dfaboutImg4:require('@/assets/images/tongze/img_about4.png')
        }
    },
    methods: {
        imgEnter(index) {
            let that = this;
            switch(index){
                case 1:
                that.dfaboutImg1 = that.aboutimg1Switch;
                break
                case 2:
                that.dfaboutImg2 = that.aboutimg2Switch;
                break
                case 3:
                that.dfaboutImg3 = that.aboutimg3Switch;
                break
                case 4:
                that.dfaboutImg4 = that.aboutimg4Switch;
                break
            }
        },
        imgLeave(index) {
            let that = this;
            switch(index){
                case 1:
                that.dfaboutImg1 = that.aboutimg1;
                break
                case 2:
                that.dfaboutImg2 = that.aboutimg2;
                break
                case 3:
                that.dfaboutImg3 = that.aboutimg3;
                break
                case 4:
                that.dfaboutImg4 = that.aboutimg4;
                break
            }
        },
    },
}
</script>