<template>
    <Layout header-class="header-transparent" :show-newsletter="true" :show-cta="false">
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-1 height-750 bg_image"  
            style="background-image: url('https://qiniu.tongzeit.com/app_website/ic_index_bg.png')">
            <div class="row" style="position: relative; left:10%;">
                <div class="col-lg-12">
                    <div class="inner">
                        <h1 class="title display-one" style="color: #2B3059;" data-aos="fade-up" data-aos-delay="150">
                            盆底健康服务平台
                        </h1>

                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="inner">
                        <div class="display-one" style="font-size: 40px;color: #2B3059;" data-aos="fade-up"
                            data-aos-delay="150">
                            专业提供一站式产康解决方案
                        </div>
                    </div>
                </div>
                <div>
                    <div class="button-group mt--40" data-aos="fade-up" data-aos-delay="150">
                        <Button variant-button-class="text-underline">
                            <VMenu>
                                <span>下载恩泽妈妈</span>
                                <template #popper>
                                    <div>
                                        <img style="height:160px;width: 160px;"
                                            src="@/assets/images/tongze/ic_enzemother_download.png" />
                                    </div>
                                </template>
                            </VMenu>
                        </Button>
                        <Button variant-button-class="text-underline">
                            <VMenu>
                                <span>下载通泽伙伴</span>
                                <template #popper>
                                    <img style="height:160px;width: 160px;"
                                        src="@/assets/images/tongze/ic_partner_download.png" />
                                </template>
                            </VMenu>
                        </Button>
                        <Button variant-button-class="text-underline">
                            <VMenu>
                                <span>下载通泽家园</span>
                                <template #popper>
                                    <img style="height:160px;width: 160px;"
                                        src="@/assets/images/tongze/ic_homeland_download.png" />
                                </template>
                            </VMenu>
                        </Button>

                    </div>
                </div>
            </div>
        </div>
        <div class="slider-area slider-style-1 height-750 bg_image mt--20" data-aos="fade-up" data-aos-delay="150"  
            style="background-image: url('https://qiniu.tongzeit.com/app_website/ic_index_item1.png')">
        </div>
        <div class="slider-area slider-style-1 height-750 bg_image mt--20" data-aos="fade-up" data-aos-delay="150"
            style="background-image: url('https://qiniu.tongzeit.com/app_website/ic_index_item2.png')">
        </div>
        <div class="slider-area slider-style-1 height-750 bg_image mt--20" data-aos="fade-up" data-aos-delay="150"
            style="background-image: url('https://qiniu.tongzeit.com/app_website/ic_index_item3.png')">
        </div>
        <div class="slider-area slider-style-1 height-750 bg_image mt--20" data-aos="fade-up" data-aos-delay="150"
            style="background-image: url('https://qiniu.tongzeit.com/app_website/ic_index_item4.png')">
        </div>
        <div class="slider-area slider-style-1 height-350 bg_image mt--20" data-aos="fade-up" data-aos-delay="150"
            style="background-image: url('https://qiniu.tongzeit.com/app_website/ic_index_item5.png')">
            <div class="container">
                <Counter :counter-style="5" text-align="center" :counter-data="counterFiveData"
                    column="col-lg-2 col-md-6 col-sm-6 col-12" />
            </div>
        </div>
        <div class="slider-area slider-style-1 height-750 bg_image mt--20" data-aos="fade-up" data-aos-delay="150"
            style="background-image: url('https://qiniu.tongzeit.com/app_website/ic_index_item6.png')">
            <div class="row" style="position: relative; left:60%;">
                <div class="col-lg-12">
                    <div class="inner">
                        <div class="display-one" style="font-size: 40px;color: #FFFFFF;" data-aos="fade-up"
                            data-aos-delay="150">
                            一站式医疗级产康开启者
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="inner">
                        <div class="display-one" style="font-size: 32px;color: #FFFFFF;" data-aos="fade-up"
                            data-aos-delay="150">
                            让产后妈妈更健康
                        </div>
                    </div>
                </div>
                <div class="col-lg-5" style="margin-top: 40px;">
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="row">
                                <div class="text-center col-lg-12">
                                    <div class="col-lg-12">
                                        <img style="height:120px;width: 120px;"
                                            src="@/assets/images/tongze/ic_enzemother_download.png" />
                                    </div>
                                    <div class="col-lg-12" style="margin-top: 20px;">
                                        <span style="font-size: 16px;color: #FFFFFF;">恩泽妈妈</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="row">
                                <div class="text-center col-lg-12">
                                    <div class="col-lg-12">
                                        <img style="height:120px;width: 120px;"
                                            src="@/assets/images/tongze/ic_partner_download.png" />
                                    </div>
                                    <div class="col-lg-12" style="margin-top: 20px;">
                                        <span style="font-size: 16px;color: #FFFFFF;">通泽伙伴</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="row">
                                <div class="text-center col-lg-12">
                                    <div class="col-lg-12">
                                        <img style="height:120px;width: 120px;"
                                            src="@/assets/images/tongze/ic_homeland_download.png" />
                                    </div>
                                    <div class="col-lg-12" style="margin-top: 20px;">
                                        <span style="font-size: 16px;color: #FFFFFF;">通泽家园</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from '../../components/common/Layout'
import Separator from '../../components/elements/separator/Separator'
import SectionTitle from '../../components/elements/sectionTitle/SectionTitle'
import AboutFour from '../../components/elements/about/AboutFour'
import ServiceOne from '../../components/elements/service/ServiceOne'
import Counter from '../../components/elements/counterUp/Counter'
import Button from "../../components/elements/button/Button";

export default {
    name: 'About',
    components: { Button, Counter, ServiceOne, AboutFour, SectionTitle, Separator, Layout },
    data() {
        return {
            counterFiveData: [
                {
                    number: 478,
                    title: '月子会所',
                },
                {
                    number: 373,
                    title: '大型三甲医院',
                },
                {
                    number: 178,
                    title: '医美机构',
                },
                {
                    number: 1746,
                    title: '母婴店',
                },
                {
                    number: 342,
                    title: '产康中心',
                },
                {
                    number: 120000,
                    title: '产后妈妈',
                }
            ]
        }
    },
    methods: {
        switchImg() {

        }
    },
}
</script>

<style>
.v-popper__popper {
    outline: none;
}
</style>
