<template>
    <Layout header-class="header-transparent" :show-newsletter="true" :show-cta="false">
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-1 height-850 bg_image"
            style="background-image: url('https://qiniu.tongzeit.com/app_website/ic_index_item1.png')">
        </div>
        <!-- End Slider Area -->
        <div class="rwt-contact-area rn-section-gap">
            <div class="row">
                <div class="col-lg-12 mb--40 text-center">
                    <SectionTitle text-align="center" subtitle="Products Center" title="产品中心" />
                    <span class="subtitle b2 text-capitalize color-black">通泽医疗系列产后恢复产品，为您提供全周期产康技术支持，悉心呵护每一位产后妈妈。</span>
                </div>
            </div>
        </div>

        <div class="container">
            <carousel :perPage="3" :navigationEnabled="true" class="device-block">
                <slide v-for="(item, index) in dataArray" :key="index" @slideclick="gotoDetail(item.productId, index)">
                    <div v-if="index==8" class="text-center device-item device_height">
                        <div class="device-text-more ">
                            <router-link to="/tongze-productlist">更多</router-link>
                        </div>
                    </div>
                    <div v-else class="text-center device-item">
                        <div class="col-lg-12 device-block-img">
                            <img :src="item.productIcon" />
                        </div>
                        <div class="device-line-block">
                            <div class="device-line-block-sub"></div>
                        </div>
                        <div class="device-text-block">
                            <span>{{ item.productName }}</span>
                        </div>
                        <div class="device-blue-block"></div>
                    </div>
                </slide>
            </carousel>
        </div>

    </Layout>
</template>

<script>
import Layout from '../../components/common/Layout'
import Separator from '../../components/elements/separator/Separator'
import SectionTitle from '../../components/elements/sectionTitle/SectionTitle'
import AboutFour from '../../components/elements/about/AboutFour'
import ServiceOne from '../../components/elements/service/ServiceOne'
import Counter from '../../components/elements/counterUp/Counter'
import VueSlickCarousel from 'vue-slick-carousel'

import axios from 'axios'

export default {
    name: 'About',
    components: { Counter, ServiceOne, VueSlickCarousel, AboutFour, SectionTitle, Separator, Layout },
    data() {
        return {
            pages: 1,
            currentPage: 1,
            dataArray: []
        }
    },
    methods: {
        setCarouselSwiping(state) {
            this.swiping = state;
        },
        getMyData(pageNum = 1) {
            let that = this

            const instance = axios.create({
                baseURL: process.env.VUE_APP_URL,
                timeout: 3000,
                // headers: { 'X-Custom-Header': 'foobar' }
            });

            instance.post('official_website/getProductList', {
            }, {
                params: {
                    pageNum: pageNum,
                    limitSize: 9
                }
            }).then(function (response) {
                let sumTotal = response.data.data.recordCounts

                that.dataArray = response.data.data.dataList
                that.pages = Math.ceil(sumTotal / 10)
                that.currentPage = pageNum

            }).catch(function (error) {
                console.log(error);
            });
        },
        gotoDetail(id, index) {
            if (index != 8) {
                this.$router.push({ path: '/tongze-productdetail', query: { productId: id } })
            }
        }
    },
    mounted: function () {
        this.getMyData()
    }
}
</script>

<style lang="scss" scoped>
.device-block {
    // margin-left: 90px;
    // margin-right: 90px;
}

.device-item {
    background: #FFFFFF;
    box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.07);
    margin-left: 20px;
    margin-right: 20px;
}

.device-block-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 360px;
    padding: 60px;
    pointer-events: none;
}

.device-line-block {
    height: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 9px;
    padding-bottom: 9px;
}

.device-line-block-sub {
    background: #EBEBEB;
    height: 2px;
}

.device-text-block {
    height: 70px;
    margin-left: 20px;
    margin-right: 20px;
    font-size: 24px;
    font-weight: 400;
    color: #404040;
    line-height: 33px;
}


.device-blue-block {
    height: 20px;
    background: #2B3059;
}

.device_height {
    height: 470px;
}

.device-text-more {
    height: 70px;
    margin-left: 20px;
    margin-right: 20px;
    font-size: 24px;
    font-weight: 400;
    color: #404040;
    line-height: 470px;
}
</style>
