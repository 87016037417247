<template>
    <Layout header-class="header-transparent" :show-newsletter="true" :show-cta="false">
        <!-- Start Slider Area -->
        <!-- <div class="slider-area slider-style-1 height-850 bg_image"
            :style="{'background-image': `url(${productImages})`}">
        </div> -->
        <!-- End Slider Area -->
        <div class="rwt-contact-area rn-section-gap">
            <div class="container">
                <span >  当前位置： <router-link to="/tongze-product" >产品中心</router-link><router-link to="/tongze-productlist" >>产康仪器</router-link>>产品详情</span>
            <div class="row">
                <div class="col-lg-12 mb--40 text-center">
                    <p v-html="richText"></p>
                </div>
            </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from '../../components/common/Layout'
import Separator from '../../components/elements/separator/Separator'
import SectionTitle from '../../components/elements/sectionTitle/SectionTitle'
import AboutFour from '../../components/elements/about/AboutFour'
import ServiceOne from '../../components/elements/service/ServiceOne'
import Counter from '../../components/elements/counterUp/Counter'

import axios from 'axios'

export default {
    name: 'About',
    components: { Counter, ServiceOne, AboutFour, SectionTitle, Separator, Layout },
    data() {
        return {
            richText: "",
            productImages:""
        }
    },
    methods: {
        getMyData() {
            let that = this

            const instance = axios.create({
                baseURL: process.env.VUE_APP_URL,
                timeout: 3000,
                // headers: { 'X-Custom-Header': 'foobar' }
            });

            instance.post('official_website/productDetailInfo', {
            }, {
                params: {
                    productId: this.$route.query.productId
                }
            }).then(function (response) {

                that.richText = response.data.data.productIntro
                that.productImages =  response.data.data.productImages
            }).catch(function (error) {
                console.log(error);
            });
        }
    },
    mounted: function () {
        this.getMyData()
    }
}
</script>
